import { EventEmitter, Injectable } from '@angular/core';

import { config, languages, shortcuts } from 'app/config';

import { v4 as uuidv4 } from 'uuid';
import { HttpService } from "./http.service";
import { BehaviorSubject, lastValueFrom, Observable } from "rxjs";
import { Project } from "../models/project";
import { Language } from "../models/language";
import { Shortcut } from "../models/shortcut";
import { Audio } from "../models/audio";
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: 'root',
})

export class ConfigService {
    config!: Project
    configOld: any = config
    languages: Language[] = languages
    welcomeAudios: Audio[] = [];

    // config translate components
    shortcuts: Shortcut[] = []
    shortcutIcons: string[] = [
        "assets/icons/contactUs.svg",
        "assets/icons/hoursOfOperation.svg",
        "assets/icons/symplicityGuaranteed.svg"
    ]

    maleicon = '/assets/icons/male.svg';
    femaleicon = '/assets/icons/women.svg';

    private projectId: string | null = null;
    private apiHost: string = environment.apiUrl
    private orchestratorUrl: string = environment.orchestratorUrl
    private wssScheme: string = environment.wssScheme
    private httpScheme: string = environment.httpScheme

    private isFirstOpen: boolean = false;

    scrollToBottomEvent: EventEmitter<any> = new EventEmitter();
    blurEfectEvent: EventEmitter<any> = new EventEmitter();
    avatarBackground = new BehaviorSubject<any>({});
    avatarBackground$ = this.avatarBackground.asObservable();
    avatarIcon = new BehaviorSubject<any>({});
    avatarIcon$ = this.avatarIcon.asObservable();

    constructor(private http: HttpService) {}

    // getters
    getHost () {
        return this.apiHost;
    }

    getOrchestrationHost () {
        return this.orchestratorUrl;
    }

    getWssScheme () {
        return this.wssScheme;
    }

    getHttpScheme () {
        return this.httpScheme;
    }

    getProjectId(): string | null {
        return this.projectId;
    }

    getAnonymousId(): string {
        const declare = (key: string): string => {
            let id = localStorage.getItem(key) ?? uuidv4();
            localStorage.setItem(key, id);
            return id;
        }

        return declare("anonymousId");
    }

    getDesignId(): string {
        //return this.config.design.id;
        return '3d41f6bc-ad1e-47fe-822d-c0653c22a3f8';
    }

    getFromObject(needle: string, haystack: { [key: string]: any }): any {
        const keys = needle.split('.');
        const item = haystack[keys[0]];
        if (item === undefined || keys.length < 2) {
            return item;
        } else {
            keys.shift();
            return this.getFromObject(keys.join('.'), item);
        }
    }

    isComponentEnabled(uuid: string): boolean {
        return (
            this.getFromObject('configOld.configAvatar.components', this)?.find(
                (c: { id: string; enabled: boolean }) => c.id === uuid && c.enabled,
            ) ?? false
        );
    }

    getComponentStyle(uuid: string) {
        return (
            this.getFromObject('configOld.configAvatar.design.components', this)?.find(
                (c: { id: string }) => c.id === uuid,
            ) ?? {}
        );
    }

    getLanguages() {
        //return this.languages;
        return this.config.project_languages;
    }

    getLanguage(locale: string) {
        return this.getLanguages().find(l => l.locale === locale)
    }

    getSelectedLanguage () {
        return this.config.project_languages.find(l => l.selected);
    }

    getProjectName(): string {
        return this.config.project_name;
    }

    getAgentName(): string {
        return this.config.agent_name;
    }

    getAgentId(): string {
        return this.config.agent_id;
    }

    getShortcuts(language: string) {
        let s = this.config.project_shortcuts.find(s => s.locale === language);
        if (!s) return [];

        return s.shortcuts.map((shortcut, index) => ({
            iconPath: this.shortcutIcons[index],
            text: shortcut.name,
        }));
    }

    getDirectionFromLanguage(locale: string) {
        const language = this.getLanguage(locale)
        return language ? language.direction : 'ltr'
    }

    getWelcomeAudio (language: string | null = null) {
        return this.welcomeAudios.find(wa => wa.language === language) ?? null;
    }

    getWelcomeAudios () {
        return this.welcomeAudios;
    }

    getFirstTime () {
        return this.isFirstOpen;
    }

    getPresenter(): Observable<any> {
        return this.avatarBackground;
    }

    getAvatarIcon(): Observable<any> {
        return this.avatarIcon;
    }

    getDesignType() {
        return environment.design;
    }

    // setters
    setProjectId (id: string | null) {
        this.projectId = id;
    }

    setProjectConfig (config: any) {
        console.log(config)

        const {
            project_id,
            project_name,
            agent_name,
            agent_id,
            design,
            project_languages,
            project_shortcuts
        } = config;

        this.config = {
            project_id: project_id,
            project_name: project_name,
            agent_name: agent_name,
            agent_id: agent_id,
            design: design,
            project_languages: project_languages,
            project_shortcuts: project_shortcuts
        }

        this.setSelectedLanguage("en-US");
    }

    setSelectedLanguage (locale: string) {
        this.config.project_languages = this.config.project_languages.map(language => {
            return {
                id: language.id,
                name: language.name,
                icon: ((): string => { const l = this.languages.find((l: any) => l.locale === language.locale); return l?.icon ?? ""; })(),
                locale: language.locale,
                direction: ((): string => { const l = this.languages.find((l: any) => l.locale === language.locale); return l?.direction ?? "ltr"; })(),
                selected: language.locale === locale,
                created_at: language.created_at,
                updated_at: language.updated_at,
                presenter_url: language.presenter_url,
                voices: language.voices
            }
        })
    }

    setWelcomeAudios (audio: Audio[] | Audio) {
        if (!Array.isArray(audio)) audio = [audio];
        this.welcomeAudios.push(...audio);
    }

    setFirstOpen () {
        if (!this.isFirstOpen)
            this.isFirstOpen = true;
    }

    setPresenter(language: string): void {
        this.avatarBackground.next(this.getLanguage(language)?.presenter_url);
    }

    setAvatarIcon(gender: string): void {
        const icon = (gender === 'male') ? this.maleicon : (gender === 'female') ? this.femaleicon : null;
        this.avatarIcon.next(icon);
    }

    isSupportedLanguageIcon(locale: string): boolean {
        return !!this.languages.find(l => l.locale === locale);
    }

    setUpConfigAudios (language: string): Audio[] {
        return [
            {
                language: language,
                blob: null,
                source: this.getLanguage(language)?.voices[0].sound!,
            }
        ];
    }

    // TODO - old way, using RESTFUL api
    /*async createAiAgent (user: any) {
        try {
            return await lastValueFrom(
                this.http
                    .setHost(`${this.getHttpScheme()}://${this.getHost()}/agent/${this.getProjectId()}/create`)
                    .setMethod("POST")
                    .setContent({
                        "id":1,
                        "name":`${user.first_name} ${user.last_name}`,
                        "nationality": user.nationality,
                        "current_residency": user.current_residency,
                        "original_residency": user.original_residency ?? "",
                        "age":user.age,
                        "gender":user.gender,
                        "insurance_policy":user.policy
                    })
                    .setHeaders({
                        "content-type": "application/json",
                        "Authorization": `Bearer ${this.getAnonymousId()}`
                    })
                    .create()
            );
        } catch (e: any) {
            return {
                status: 500,
                body: {}
            }
        }
    }*/

    async fetchConfiguration () {
        try {
            return await lastValueFrom(
                this.http
                    .setHost(`${this.getHttpScheme()}://${this.getHost()}/agent/${this.getProjectId()}/${this.getDesignType()}/config`)
                    .setMethod("GET")
                    .setHeaders({
                        "content-type": "application/json",
                        "Authorization": `Bearer ${this.getAnonymousId()}`
                    })
                    .create()
            );
        } catch (e: any) {
            throw new Error(`could not get required configuration: ${e.toString()}`);
        }
    }

    async fetchWelcomeAudios (audios: Audio[]) {
        return await Promise.all(audios.map(async audio => {
            const request = await fetch(audio.source,  { mode: 'no-cors' });
            audio.blob = await request.blob();
            return audio;
        }));
    }
}
